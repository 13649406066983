<template>
    <tr class="product__info">
        <td class="product-name-container">
            <input
                type="checkbox"
                class="select-part"
                :id="customId"
                :checked="isPartSelected(customId)"
                :disabled="isReadOnly"
                @click="togglePartSelection($event)"
            />
            <i
                :class="[
                    'fa-solid fa-chevron-right product-quick-details',
                    { active: isDetailsOpen },
                ]"
                @click="toggleQuickDetails"
            />
            <a
                class="product-name"
                @click="handleGoToDetails(product.part_number, product.brand, detailsPath)"
                rel="noopener noreferrer"
                target="_blank"
            >
                {{ product.part_number }}
            </a>
            <button
                v-if="hasDiagram"
                class="interactive-diagrams-btn"
                @click="openDiagramPage(product)"
            >
                <fmmp-i18n text="Interactive Diagrams" />
            </button>
        </td>
        <td class="product-submodel" v-if="checkIfArrayHasColumn(columnNames.SUB_MODEL)">
            {{
                transformToMissedValue(
                    typeof product.sub_model === "object" && product.sub_model.value,
                )
            }}
        </td>
        <td class="product-drive-wheel" v-if="checkIfArrayHasColumn(columnNames.DRIVE_WHEEL)">
            {{ transformToMissedValue(typeof product.drive === "object" && product.drive.value) }}
        </td>
        <td class="product-engine-base" v-if="checkIfArrayHasColumn(columnNames.ENGINE_BASE)">
            {{ transformToMissedValue(product.engine_base_value) }}
        </td>
        <td class="product-engine-vin" v-if="checkIfArrayHasColumn(columnNames.ENGINE_VIN)">
            {{ transformToMissedValue(product.engine_vin && product.engine_vin.value) }}
        </td>
        <td class="product-position" v-if="checkIfArrayHasColumn(columnNames.POSITION)">
            {{ transformToMissedValue(product.position && product.position.value) }}
        </td>
        <td
            :class="['product-qualifiers', { 'align-left': isQualifierAlignedLeft }]"
            v-if="checkIfArrayHasColumn(columnNames.QUALIFIERS)"
        >
            {{ transformToMissedValue(product.additional_fit_criteria) }}
        </td>
        <td class="product-vehicle-quantity" v-if="checkIfArrayHasColumn(columnNames.QUANTITY)">
            {{ transformToMissedValue(product.qty) }}
        </td>
    </tr>
</template>

<script>
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";
import { generateCustomId, getQsParams, openDiagramPage } from "../helpers";
import {
    createUrlToGoToSearchResults,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { COLUMN_NAMES } from "../constants";

export default Vue.component("driv-gpi-grid-view-item", {
    data() {
        return {
            columnNames: COLUMN_NAMES,
        };
    },
    props: {
        product: Object,
        togglePartSelection: Function,
        isPartSelected: Function,
        detailsPath: {
            type: String,
            default: "",
        },
        toggleQuickDetails: {
            type: Function,
            default: () => {},
        },
        isDetailsOpen: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        performanceEngineSearchType() {
            const { searchType } = getQsParams();
            return searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
        },
        isQualifierAlignedLeft() {
            if (this.performanceEngineSearchType) {
                return this.product.notes;
            } else {
                return this.product.additional_fit_criteria !== "N/A"
                    ? this.product.additional_fit_criteria
                    : false;
            }
        },
        hasDiagram() {
            return this.product.digital_assets;
        },
        customId() {
            return generateCustomId(this.product);
        },
    },
    methods: {
        transformToMissedValue,
        openDiagramPage,
        checkIfArrayHasColumn(columnClassName) {
            return this.columns.find((col) => columnClassName === col.className);
        },
        handleGoToDetails(partNumber, brandCode, path) {
            if (!path) return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    window.location.assign(
                        createUrlToGoToSearchResults({
                            queryObj: { part_number: partNumber, brand_code: brandCode },
                            path,
                        }),
                    );
                },
            });
        },
    },
});
</script>
