<template>
    <li class="driv-corp-list-view-item-container">
        <div class="driv-corp-list-view-item-header">
            <driv-gpi-part-name-section
                :product="product"
                :partName="product.product"
                @goToDetails="
                    () => handleGoToDetails(product.part_number, product.brand, detailsPath)
                "
                :togglePartSelection="togglePartSelection"
                :isPartSelected="isPartSelected"
                :isReadOnly="isReadOnly"
            />
            <button
                v-if="hasDiagram"
                class="interactive-diagrams-btn"
                @click="openDiagramPage(product)"
            >
                <fmmp-i18n text="Interactive Diagrams" />
            </button>
        </div>

        <div class="driv-corp-list-view-item-details">
            <div class="driv-corp-list-view-item-info">
                <fmmp-i18n
                    class="driv-corp-list-view-item-info-header"
                    text="Application criteria"
                />
                <template v-if="isApplicationCriteriaDataShown">
                    <div class="details-item" v-for="item in appCriteriaItems" :key="item.label">
                        <fmmp-i18n class="details-item-label" :text="item.label" />:
                        <span class="details-item-value">{{ item.value }}</span>
                    </div>
                </template>
                <template v-if="vcdbAttributes && isApplicationCriteriaDataShown">
                    <div class="details-item" v-for="item in vcdbAttributes" :key="item.property">
                        <fmmp-i18n class="details-item-label" :text="item.property" />:
                        <span class="details-item-value">{{ item.value }}</span>
                    </div>
                </template>
                <div v-if="product.qty" class="details-item">
                    <fmmp-i18n class="details-item-label" :text="applicationQuantity.property" />:
                    <span class="details-item-value">{{ applicationQuantity.value }}</span>
                </div>
            </div>
            <div class="driv-corp-list-view-item-additional-info" v-if="isAdditionalInfoBlockShown">
                <fmmp-i18n
                    class="driv-corp-list-view-item-info-header"
                    :text="additionalInfoTitle"
                />
                <span v-if="!performanceEngineSearchType">
                    {{ product.additional_fit_criteria }}
                </span>
                <template v-if="product.notes && performanceEngineSearchType">
                    <div class="details-item" v-for="note in product.notes" :key="note.note_id">
                        <span>{{ note.note }}</span>
                    </div>
                </template>
            </div>
        </div>
    </li>
</template>

<script>
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";
import { getQsParams, openDiagramPage } from "../helpers";

const APPLICATION_CRITERIA_ITEMS = [
    { label: "Position", property: "position" },
    { label: "Drive", property: "drive" },
    { label: "Engine base", property: "engine_base_value" },
    { label: "Designation", property: "engine_designation" },
    { label: "VIN", property: "engine_vin" },
    { label: "Sub-model", property: "sub_model" },
];

export default Vue.component("driv-gpi-list-view-item", {
    props: {
        product: Object,
        togglePartSelection: Function,
        isPartSelected: Function,
        detailsPath: {
            type: String,
            default: "",
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        performanceEngineSearchType() {
            const { searchType } = getQsParams();
            return searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;
        },
        isApplicationCriteriaDataShown() {
            const { searchType } = getQsParams();
            return ![
                PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE,
                PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY,
            ].includes(searchType);
        },
        isAdditionalInfoBlockShown() {
            if (this.performanceEngineSearchType) return this.product.notes;
            return (
                this.product.additional_fit_criteria &&
                this.product.additional_fit_criteria !== "N/A"
            );
        },
        additionalInfoTitle() {
            if (this.performanceEngineSearchType) {
                return "Application / Part Comments";
            } else if (this.engineSearchType) {
                return "Additional application criteria";
            }
            return "Qualifiers";
        },
        applicationQuantity() {
            return {
                property: "Application quantity",
                value: this.product.qty,
            };
        },
        vcdbAttributes() {
            const attributes = this.product.vcdb_attributes;
            let mappedVcdbAttrs = [];

            if (attributes) {
                for (const prop in attributes) {
                    if (attributes.hasOwnProperty(prop)) {
                        mappedVcdbAttrs = [
                            ...mappedVcdbAttrs,
                            {
                                property: prop,
                                value: attributes[prop],
                            },
                        ];
                    }
                }
            }

            return mappedVcdbAttrs;
        },
        appCriteriaItems() {
            return APPLICATION_CRITERIA_ITEMS.map(({ label, property }) => {
                if (!this.product[property]) return;

                return {
                    label,
                    value: transformToMissedValue(
                        this.getValue(property, this.product[property]),
                        "-",
                    ),
                };
            }).filter(Boolean);
        },
        hasDiagram() {
            return !!this.product.digital_assets;
        },
    },
    methods: {
        getValue(name, data) {
            const isDataTypeStringOrNumber = typeof data === "string" || typeof data === "number";
            const appCriteriaItemsWithoutEngine = [
                "position",
                "sub_model",
                "drive",
                "engine_designation",
                "engine_vin",
            ];

            if (isDataTypeStringOrNumber || name === "engine_base_value") return data;

            if (appCriteriaItemsWithoutEngine.includes(name)) return data.value;

            return "-";
        },
        handleGoToDetails(partNumber, brandCode, path) {
            if (!path) return;

            Vue.Global.Analytics.trackEvent("Part Search Results Link", "Part", partNumber, null, {
                callback: function () {
                    window.location.assign(
                        createUrlToGoToSearchResults({
                            queryObj: { part_number: partNumber, brand_code: brandCode },
                            path,
                        }),
                    );
                },
            });
        },
        openDiagramPage,
    },
});
</script>
