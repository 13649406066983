<template>
    <div>
        <div v-if="isLoading.initial" class="three-quarters-loader">
            <fmmp-i18n text="loading..." />
        </div>

        <driv-corp-part-number-results
            v-else-if="searchResultsType.partNumber"
            :partsList="partNumberList"
            :wtbPath="wtbPath"
            :error="error"
            :detailsPath="detailsPath"
            :hideWtbBtns="hideWtbBtns"
            :noPartsFound="noPartsFound"
            :isPartSelected="isPartSelected"
            :togglePartSelection="togglePartSelection"
            :sendPartsData="sendPartsData"
            :partsAmount="selectedParts.length"
            :notificationModal="notificationModal"
            :updateNotificationModalState="updateNotificationModalState"
        ></driv-corp-part-number-results>

        <driv-corp-u-joint-results
            v-else-if="searchResultsType.uJoint"
            :uJointTypes="uJointTypes"
            :uJointSpecifications="specificationsList"
            :error="error"
            :detailsPath="detailsPath"
            :isLoading="isLoading.products"
        ></driv-corp-u-joint-results>

        <driv-corp-specification-results
            v-else-if="searchResultsType.specification"
            class="driv-corp-specification-results"
            :error="error"
            :pagination="pagination"
            :handlePageChange="handlePageChange"
            :specifications="specificationsList"
            :infoModal="modalText"
            :detailsPath="detailsPath"
        ></driv-corp-specification-results>

        <driv-corp-torque-results
            v-else-if="searchResultsType.torque"
            :torqueYears="torqueYears"
            :pdfImageSrc="torquePdfImageSrc"
            :viewPdfRedirectLink="viewTorquePdfRedirectLink"
            :pdfRedirectLinkText="pdfRedirectLinkText"
            :hubPage1stLogo="torqueHubPage1stLogo"
            :hubPage2ndLogo="torqueHubPage2ndLogo"
            :cylinderPage1stLogo="torqueCylinderPage1stLogo"
            :cylinderPage2ndLogo="torqueCylinderPage2ndLogo"
            :torqueSpecRemark="torqueSpecRemark"
            :torqueApiProviderLogo="torqueApiProviderLogo"
            :torqueApiProviderText="torqueApiProviderText"
            :torqueLinkText="torqueLinkText"
            :torqueLinkPath="torqueLinkPath"
        ></driv-corp-torque-results>

        <div v-else class="driv-part-results-content">
            <div v-if="showEngineSearchTable" class="driv-engine-search-table tablet desktop">
                <driv-engine-search-filtering-table
                    :tableFilters="tableFilters"
                    :updateResults="updateEngineFiltersTable"
                    :enginePerformance="searchResultsType.performance"
                    :engineHeavy="searchResultsType.heavy"
                ></driv-engine-search-filtering-table>
            </div>

            <div v-if="!startPageLoading" class="driv-engine-search-results">
                <div class="results-summary mobile" v-if="!isInteractiveDiagram">
                    <span v-if="!noPartsFound" class="results-quantity mobile">
                        {{ pagination.total }}
                        <fmmp-i18n text="Part Results" />
                    </span>
                    <driv-gpi-update-case-btn
                        v-if="isGurusProductInsights && !noPartsFound"
                        @onClick="sendPartsData"
                        :isReadOnly="isReadOnlyVersionGpi"
                    ></driv-gpi-update-case-btn>
                    <driv-corp-list-pagination
                        v-if="isPaginationShown"
                        :savedPage="pagination.page"
                        :totalPages="pagesQty"
                        :onPageChange="handlePageChange"
                    />
                </div>
                <driv-corp-parts-result-filters
                    v-if="isFiltersShown"
                    :filters="filters"
                    :dataForSelectedFilters="dataForSelectedFilters"
                ></driv-corp-parts-result-filters>

                <driv-gpi-selected-parts-amount
                    v-if="isGurusProductInsights && !isInteractiveDiagram && !noPartsFound"
                    :partsAmount="selectedParts.length"
                ></driv-gpi-selected-parts-amount>

                <driv-engine-search-filtering-table
                    v-if="showEngineSearchTable"
                    class="driv-engine-search-table mobile"
                    :tableFilters="tableFilters"
                    :updateResults="updateEngineFiltersTable"
                    :enginePerformance="searchResultsType.performance"
                    :engineHeavy="searchResultsType.heavy"
                ></driv-engine-search-filtering-table>

                <div v-if="isLoading.products" class="three-quarters-loader" />
                <driv-empty-part-results-page
                    v-else-if="showErrorScreen"
                    :noPartsFound="noPartsFound"
                ></driv-empty-part-results-page>

                <div
                    v-else
                    :class="[
                        'driv-part-results-products-container',
                        {
                            'full-width': !isFiltersShown,
                        },
                        {
                            'margin-container': isInteractiveDiagram,
                        },
                    ]"
                >
                    <div v-if="isLoading.products" class="three-quarters-loader" />
                    <driv-corp-diagram-results
                        v-else-if="isInteractiveDiagram"
                        :detailsPath="detailsPath"
                        :diagrams="interactiveDiagrams"
                    ></driv-corp-diagram-results>

                    <div v-else-if="partsList.length">
                        <div class="results-summary">
                            <div class="results-quantity">
                                {{ pagination.total }}
                                <fmmp-i18n text="Part Results" />
                            </div>
                            <driv-gpi-update-case-btn
                                v-if="isGurusProductInsights"
                                @onClick="sendPartsData"
                                :isReadOnly="isReadOnlyVersionGpi"
                            ></driv-gpi-update-case-btn>
                            <div v-if="!hideViewSwitcher" class="view-modes">
                                <fmmp-i18n text="View Mode:" />
                                <button
                                    :class="{ active: viewMode === VIEW_MODES.LIST }"
                                    @click="setViewMode(VIEW_MODES.LIST)"
                                >
                                    <i class="fa fa-list"></i>
                                </button>
                                <button
                                    :class="{ active: viewMode === VIEW_MODES.GRID }"
                                    @click="setViewMode(VIEW_MODES.GRID)"
                                >
                                    <i class="fa fa-th"></i>
                                </button>
                            </div>
                            <driv-gpi-selected-parts-amount
                                v-if="isGurusProductInsights"
                                :partsAmount="selectedParts.length"
                            ></driv-gpi-selected-parts-amount>
                            <driv-corp-list-pagination
                                v-if="isPaginationShown"
                                :savedPage="pagination.page"
                                :totalPages="pagesQty"
                                :onPageChange="handlePageChange"
                            />
                        </div>
                        <div class="results">
                            <driv-corp-list-view
                                v-if="isPartsListShown"
                                :products="partsList"
                                :wtbPath="wtbPath"
                                :detailsPath="detailsPath"
                                :hideWtbBtns="hideWtbBtns"
                                :togglePartSelection="togglePartSelection"
                                :isPartSelected="isPartSelected"
                                :isReadOnly="isReadOnlyVersionGpi"
                            ></driv-corp-list-view>
                            <driv-corp-grid-view
                                v-else
                                :products="partsList"
                                :wtbPath="wtbPath"
                                :detailsPath="detailsPath"
                                :hideWtbBtns="hideWtbBtns"
                                :togglePartSelection="togglePartSelection"
                                :isPartSelected="isPartSelected"
                                :isReadOnly="isReadOnlyVersionGpi"
                            ></driv-corp-grid-view>
                        </div>
                        <div class="results-summary footer" v-if="isPaginationShown">
                            <div class="results-quantity">
                                {{ pagination.total }}
                                <fmmp-i18n text="Part Results" />
                            </div>
                            <driv-corp-list-pagination
                                :savedPage="pagination.page"
                                :totalPages="pagesQty"
                                :onPageChange="(v) => handlePageChange(v, true)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <driv-notification-modal
                :notificationModal="notificationModal"
                modalTitle="Part Details"
                @updateModalState="updateNotificationModalState"
            >
                <div class="modal-body">
                    <fmmp-i18n text="Max 10 items can be selected at a time." class="modal-text" />
                    <br />
                    <fmmp-i18n text="10 out of 10 are selected" class="modal-text bold" />
                </div>
            </driv-notification-modal>
        </div>
    </div>
</template>

<script>
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../common/partFinderCorporate.constants";
import {
    SPECIFICATIONS_QS_OPTIONS,
    SPECIFICATION_PROPERTIES,
    DEFAULT_TORQUE_PDF_LINK_NAME,
    CHECKBOX_ATTRIBUTE_NAME,
} from "./constants";
import {
    getFiltersToUpdate,
    getFiltersForRequest,
    getQsParams,
    mapQualifiersToConfigs,
    getUpdatedSpecificationsList,
    isUJointFilters,
    getDoubleEncodedHashParams,
    mapPartToSalesforceObj,
    generateCustomId,
    configurePartObject,
    isUJointFiltersValue,
} from "./helpers";
import { getEngineType } from "../../../common/engine-search/helpers";
import { getBrandsFromTags } from "../../../common/ymm-search/helpers";
import {
    PART_DESCRIPTION_ATTRIBUTE_NAME,
    UJOINT_ATTRIBUTES,
    U_JOINT_ATTRIBUTE_VALUE,
    ATTRIBUTES_FILTERS,
} from "../../../common/specification-search/constants";

const APPLICATION_LIST_PROPERTY = "application_list";
const APPLICATIONS_PROPERTY = "applications";
const APPLICATION_LIST_GROUP_PROPERTY = "application_group_list";
const BRAND_APPLICATION_LIST_PROPERTY = "brand_application_list";
const INTERACTIVE_DIAGRAMS_PROPERTY = "interactive_assets";
const VIEW_MODES = {
    LIST: "list",
    GRID: "grid",
};

export default Vue.component("driv-part-finder-part-results", {
    data() {
        return {
            partsList: [],
            error: false,
            filters: {
                categories: [],
                configs: {},
                partTypes: [],
                brands: [],
            },
            dataForSelectedFilters: null,
            isLoading: {
                initial: true,
                products: false,
            },
            searchResultsType: {
                vehicle: false,
                engine: false,
                partNumber: false,
                performance: false,
                heavy: false,
                specification: false,
                uJoint: false,
                torque: false,
            },
            partNumberList: {
                interchange_products: [],
                products: [],
            },
            viewMode: VIEW_MODES.LIST,
            VIEW_MODES,
            isDesktopView: false,
            pagination: {
                page: 1,
                limit: 50,
                total: 0,
            },
            partsApiCancelSrc: null,
            tableFilters: [],
            subBrand: "",
            specificationsList: {},
            uJointTypes: [],
            interactiveDiagrams: [],
            torqueYears: [],
            selectedParts: [],
            notificationModal: false,
            noPartsFound: false, // Only for GPI
            startPageLoading: false, // Only for GPI
        };
    },
    props: {
        hideViewSwitcher: {
            type: Boolean,
            default: false,
        },
        hideWtbBtns: {
            type: Boolean,
            default: false,
        },
        defaultViewMode: {
            type: String,
            default: VIEW_MODES.LIST,
        },
        wtbPath: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
        tags: {
            type: String,
            default: "",
        },
        modalText: {
            type: String,
            default: "",
        },
        torquePdfImageSrc: {
            type: String,
            default: "",
        },
        viewTorquePdfRedirectLink: {
            type: String,
            default: "",
        },
        pdfRedirectLinkText: {
            type: String,
            default: DEFAULT_TORQUE_PDF_LINK_NAME,
        },
        torqueHubPage1stLogo: {
            type: String,
            default: "",
        },
        torqueHubPage2ndLogo: {
            type: String,
            default: "",
        },
        torqueCylinderPage1stLogo: {
            type: String,
            default: "",
        },
        torqueCylinderPage2ndLogo: {
            type: String,
            default: "",
        },
        torqueSpecRemark: {
            type: String,
            default: "",
        },
        torqueApiProviderLogo: {
            type: String,
            default: "",
        },
        torqueApiProviderText: {
            type: String,
            default: "",
        },
        torqueLinkText: {
            type: String,
            default: "",
        },
        torqueLinkPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        isFiltersShown() {
            const { categories, configs, partTypes } = this.filters;

            if (categories.length || partTypes.length) return true;
            if (!configs) return false;
            const configsFilters = Object.values(configs);

            return configsFilters.some((filter) => filter.length);
        },
        isPartsListShown() {
            return this.viewMode === this.VIEW_MODES.LIST || !this.isDesktopView;
        },
        isPaginationShown() {
            return this.pagination.total > this.pagination.limit;
        },
        pagesQty() {
            return Math.ceil(this.pagination.total / this.pagination.limit);
        },
        showEngineSearchTable() {
            return (
                (this.searchResultsType.engine ||
                    this.searchResultsType.performance ||
                    this.searchResultsType.heavy) &&
                this.tableFilters.length >= 1
            );
        },
        isInteractiveDiagram() {
            const { isInteractiveDiagram } = getQsParams();
            return isInteractiveDiagram;
        },
        isGurusProductInsights() {
            return window.brand === "gpi";
        },
        selectedPartsMap() {
            return this.selectedParts.reduce((selectedPartsMap, part) => {
                return { ...selectedPartsMap, [part.id]: part };
            }, []);
        },
        showErrorScreen() {
            return this.error || this.noPartsFound;
        },
        isReadOnlyVersionGpi() {
            const { signed_request } = getQsParams();
            return !signed_request;
        },
    },
    watch: {
        isPartsListShown: function () {
            if (!this.searchResultsType.partNumber && !this.partsApiCancelSrc) {
                const filters = getFiltersForRequest(this.filters);
                this.getPartsData(filters);
            }
        },
        partsList: function () {
            if (!this.partsList.length && !this.interactiveDiagrams?.length) {
                this.error = true;
            }
        },
    },
    created() {
        this.checkDesktopView();
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("hashchange", this.handleUrlChange, false);

        this.subBrand = getBrandsFromTags(this.tags);

        const qsParams = getQsParams();
        const { vehicleGroupIds, searchType, signed_request } = qsParams;
        const vehicleGroupIdsValue = vehicleGroupIds?.value;

        switch (searchType) {
            case PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE:
                this.showPartResults({
                    searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                    qsParams,
                    view: this.defaultViewMode || VIEW_MODES.LIST,
                });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.PART_NUMBER_SEARCH:
                this.searchResultsType.partNumber = true;
                Vue.CatalogApi.CatalogApiService.getByPartNumber(
                    decodeURIComponent(qsParams.part),
                    this.subBrand,
                    signed_request,
                )
                    .then((resp) => {
                        this.partNumberList = resp;
                        this.error = !resp?.products.length && !resp?.interchange_products.length;
                        if (this.error && this.isGurusProductInsights) {
                            this.noPartsFound = true;
                        }
                    })
                    .catch(this.errorHandler)
                    .finally(() => {
                        this.isLoading.initial = false;
                    });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE:
                const { engineMfr, engineBase, engineUsage } = getQsParams();
                Vue.CatalogApi.CatalogApiService.getEngineVersion({
                    vehicleGroupIds: vehicleGroupIdsValue,
                    mfrId: engineMfr?.value,
                    baseId: engineBase?.value,
                    usageId: engineUsage?.value,
                    engineType: getEngineType(vehicleGroupIdsValue),
                })
                    .then((resp) => {
                        this.tableFilters = resp.engine_versions;
                        this.showPartResults({
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE,
                            qsParams,
                            view: VIEW_MODES.GRID,
                        });
                    })
                    .catch(this.errorHandler)
                    .finally(() => {
                        this.isLoading.initial = false;
                    });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE:
                const { vehicle, performanceMfr, performanceBase } = getQsParams();
                Vue.CatalogApi.CatalogApiService.getEnginePerformanceVersion({
                    vehicleGroupIds: vehicleGroupIdsValue,
                    vehicleTypeId: vehicle?.value,
                    mfrId: performanceMfr?.value,
                    baseId: performanceBase?.value,
                })
                    .then((resp) => {
                        this.tableFilters = resp.engine_versions;
                        this.showPartResults({
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE,
                            qsParams,
                            view: VIEW_MODES.GRID,
                        });
                    })
                    .catch(this.errorHandler)
                    .finally(() => {
                        this.isLoading.initial = false;
                    });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY:
                const { heavyMfr, heavyBase } = getQsParams();
                Vue.CatalogApi.CatalogApiService.getEngineHeavyDutyVersion({
                    vehicleGroupIds: vehicleGroupIdsValue,
                    mfrId: heavyMfr?.value,
                    baseId: heavyBase?.value,
                })
                    .then((resp) => {
                        this.tableFilters = resp.engine_versions;
                        this.showPartResults({
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY,
                            qsParams,
                            view: VIEW_MODES.GRID,
                        });
                    })
                    .catch(this.errorHandler)
                    .finally(() => {
                        this.isLoading.initial = false;
                    });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION:
                this.searchResultsType.specification = true;
                const hashValue = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);
                const attributeFilters = hashValue[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS];
                const initialAttributeRangeFilters =
                    hashValue[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS];
                let partDescriptionFilter = attributeFilters?.find(
                    (filter) => filter.name === PART_DESCRIPTION_ATTRIBUTE_NAME,
                );

                partDescriptionFilter = {
                    [ATTRIBUTES_FILTERS]: [partDescriptionFilter],
                };

                if (initialAttributeRangeFilters) {
                    hashValue[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS] =
                        this.prepareRangeFilters(initialAttributeRangeFilters);
                }

                const filters = hashValue || {};
                this.getUJointTypes(filters)
                    .then((isUjointSearch) => {
                        if (isUjointSearch) return;
                        return this.getSpecificationsPartData(partDescriptionFilter || {});
                    })
                    .then(() => {
                        // if there are any filters except part description, make additional call
                        if (
                            hashValue[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS]?.length ||
                            (partDescriptionFilter && attributeFilters?.length > 1) ||
                            (!partDescriptionFilter && attributeFilters?.length)
                        )
                            this.getSpecificationsPartData(filters);
                    });
                break;
            case PART_FINDER_CORPORATE_SEARCH_TYPES.TORQUE:
                this.searchResultsType.torque = true;
                return Vue.CatalogApi.CatalogApiService.getTorqueYears()
                    .then((resp) => {
                        this.torqueYears = Object.values(resp)[0];
                    })
                    .catch(this.errorHandler)
                    .finally(() => {
                        this.isLoading.initial = false;
                    });
            default:
                this.isLoading.initial = false;

                if (this.isGurusProductInsights) {
                    this.startPageLoading = true;
                    break;
                }

                this.error = true;
                break;
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("hashchange", this.handleUrlChange, false);
    },
    methods: {
        setViewMode(newViewMode) {
            const updateQsParams = this.updateQsParams;
            this.viewMode = newViewMode;
            this.selectedParts = [];

            Vue.Global.Analytics.trackEvent("part-results", "change-view-mode", newViewMode, null, {
                callback: function () {
                    updateQsParams({ viewType: newViewMode });
                },
            });
        },
        handleResize() {
            this.checkDesktopView();
        },
        errorHandler(error) {
            if (!Vue.$http.isCancel(error)) {
                this.error = true;
                this.isLoading.products = false;
                this.isLoading.initial = false;
                this.partsApiCancelSrc = null;
            }
        },
        getUJointTypes(filters) {
            const isFiltersEmpty = !Object.keys(filters).length;
            const isUJoint =
                this.searchResultsType.uJoint ||
                isUJointFilters(filters) ||
                isUJointFiltersValue(filters);

            if (isFiltersEmpty || isUJoint) {
                const { brands, partTypeId } = getQsParams();
                return Vue.CatalogApi.CatalogApiService.getSpecificationPartDescription({
                    partTypeId: partTypeId?.value,
                    brandCodes: brands?.value,
                    attributeName: U_JOINT_ATTRIBUTE_VALUE,
                })
                    .then((resp) => {
                        this.uJointTypes = resp[UJOINT_ATTRIBUTES];
                        this.searchResultsType.uJoint = !!this.uJointTypes?.length;
                        this.isLoading.initial = false;

                        return this.searchResultsType.uJoint;
                    })
                    .catch(this.errorHandler);
            }

            this.searchResultsType.uJoint = false;
            return Promise.resolve(false);
        },
        checkDesktopView() {
            const desktopCssBreakpoint = 1025;
            const screenWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            this.isDesktopView = screenWidth >= desktopCssBreakpoint;
        },
        prepareRangeFilters(rangeFilters) {
            return rangeFilters?.map((filter) => ({
                name: filter.name?.value,
                uom: filter.name?.uom,
                value_from: filter.value_from || null,
                value_to: filter.value_to || null,
            }));
        },
        getSpecificationsPartData(filtersData) {
            this.isLoading.products = true;
            this.partsApiCancelSrc?.cancel();
            this.partsApiCancelSrc = Vue.CatalogApi.CatalogHttpService.getCancelTokenSource();
            const { brands, partTypeId, page } = getQsParams();
            let filtersPayload = filtersData;
            if (!filtersData) {
                const hashFilters = getDoubleEncodedHashParams(SPECIFICATIONS_QS_OPTIONS);

                hashFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS] =
                    this.prepareRangeFilters(
                        hashFilters[SPECIFICATION_PROPERTIES.ATTRIBUTE_RANGE_FILTERS],
                    );

                filtersPayload = hashFilters || {};
            }

            return Vue.CatalogApi.CatalogApiService.getSpecificationResults({
                params: {
                    brandCodes: brands?.value,
                    partTypeId: partTypeId?.value,
                    payload: filtersPayload,
                    page: this.pagination?.page || page,
                },
                cancelToken: this.partsApiCancelSrc.token,
            })
                .then((resp) => {
                    const { pagination, ...listData } = resp;
                    this.specificationsList = getUpdatedSpecificationsList({
                        newListData: listData,
                        filters: filtersPayload,
                        currentListData: this.specificationsList,
                    });
                    this.error = !(
                        listData[SPECIFICATION_PROPERTIES.SPECIFICATION_LIST_PROPERTY] &&
                        listData[SPECIFICATION_PROPERTIES.SPECIFICATION_LIST_PROPERTY][
                            SPECIFICATION_PROPERTIES.SPECIFICATIONS_PROPERTY
                        ]?.length
                    );
                    if (!!listData[SPECIFICATION_PROPERTIES.UJOINT_ATTRIBUTES]?.length) {
                        this.error = false;
                    }
                    this.pagination = pagination;
                    this.partsApiCancelSrc = null;
                    this.isLoading.products = false;
                })
                .catch(this.errorHandler)
                .finally(() => {
                    this.isLoading.initial = false;
                });
        },
        getPartsData(filters) {
            this.isLoading.products = true;
            this.partsApiCancelSrc?.cancel();
            this.partsApiCancelSrc = Vue.CatalogApi.CatalogHttpService.getCancelTokenSource();

            return this.getPartsDataRequest(filters)
                .then((response) => {
                    this.error = false;
                    this.interactiveDiagrams = response[INTERACTIVE_DIAGRAMS_PROPERTY] || [];
                    if (!this.interactiveDiagrams?.length) {
                        this.partsList = this.isPartsListShown
                            ? response[APPLICATION_LIST_PROPERTY][APPLICATIONS_PROPERTY]
                            : response[APPLICATION_LIST_GROUP_PROPERTY][
                                  BRAND_APPLICATION_LIST_PROPERTY
                              ];
                    }
                    if (
                        !this.partsList.length &&
                        !this.interactiveDiagrams.length &&
                        this.isGurusProductInsights
                    ) {
                        this.noPartsFound = true;
                    }
                    this.pagination = response.pagination;
                    this.isLoading.products = false;
                    this.partsApiCancelSrc = null;
                    this.saveFilters(response, !!filters);
                })
                .catch(this.errorHandler)
                .finally(() => {
                    this.isLoading.initial = false;
                });
        },
        getPartsDataRequest(filters) {
            const {
                type,
                make,
                model,
                year,
                vehicle,
                mfr,
                equipmentModel,
                equipmentYear,
                searchType,
                vehicleGroupIds,
                engineMfr,
                performanceMfr,
                heavyMfr,
                heavyBase,
                engineBase,
                engineFilter,
                engineUsage,
                performanceBase,
                isInteractiveDiagram,
                brandInfo,
                signed_request,
            } = getQsParams();

            if (searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE) {
                if (engineFilter) {
                    const vehicleGroupIdsValue = vehicleGroupIds?.value;
                    return Vue.CatalogApi.CatalogApiService.getEnginePartsList({
                        params: {
                            vehicleGroupIds: vehicleGroupIdsValue,
                            mfrId: engineMfr?.value,
                            baseId: engineBase?.value,
                            usageId: engineUsage?.value,
                            engineType: getEngineType(vehicleGroupIdsValue),
                            engineVersion: engineFilter,
                            page: this.pagination.page,
                            viewType: this.isPartsListShown ? VIEW_MODES.LIST : VIEW_MODES.GRID,
                            filters,
                        },
                        cancelToken: this.partsApiCancelSrc.token,
                    });
                }
            }

            if (searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE) {
                return Vue.CatalogApi.CatalogApiService.getEnginePerformancePartsList({
                    params: {
                        vehicleGroupIds: vehicleGroupIds.value,
                        vehicleTypeId: vehicle?.value,
                        mfrId: performanceMfr?.value,
                        baseId: performanceBase?.value,
                        engineVersion: engineFilter,
                        page: this.pagination.page,
                        viewType: this.isPartsListShown ? VIEW_MODES.LIST : VIEW_MODES.GRID,
                        filters,
                    },
                    cancelToken: this.partsApiCancelSrc.token,
                    signed_request,
                });
            }

            if (searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY) {
                if (engineFilter) {
                    return Vue.CatalogApi.CatalogApiService.getEngineHeavyDutyPartsList({
                        params: {
                            vehicleGroupIds: vehicleGroupIds?.value,
                            mfrId: heavyMfr?.value,
                            baseId: heavyBase?.value,
                            engineVersion: engineFilter,
                            page: this.pagination.page,
                            viewType: this.isPartsListShown ? VIEW_MODES.LIST : VIEW_MODES.GRID,
                            filters,
                        },
                        cancelToken: this.partsApiCancelSrc.token,
                        signed_request,
                    });
                }
            }

            return Vue.CatalogApi.CatalogApiService.getCorporatePartsList({
                params: {
                    typeId: type?.value,
                    makeId: make?.value,
                    modelId: model?.value,
                    yearId: year?.value,
                    vehicleId: vehicle?.value,
                    mfrId: mfr?.value,
                    equipmentModelId: equipmentModel?.value,
                    equipmentYearId: equipmentYear?.value,
                    page: this.pagination.page,
                    limit: this.pagination.limit,
                    viewType: this.isPartsListShown ? VIEW_MODES.LIST : VIEW_MODES.GRID,
                    filters,
                    brand: this.subBrand,
                    isInteractiveDiagram,
                    brandCode: brandInfo?.value,
                },
                cancelToken: this.partsApiCancelSrc.token,
                signed_request,
            });
        },
        showPartResults({ searchType, qsParams, view }) {
            this.searchResultsType[searchType] = true;
            this.pagination.page = qsParams.page || 1;
            this.viewMode = qsParams.viewType?.toLowerCase() || view;

            this.getPartsData().then(() => {
                const filters = getFiltersForRequest(this.filters);
                if (filters) this.getPartsData(filters);
            });
        },
        handlePageChange(newPageNum, withScrollToTop = false) {
            this.pagination.page = newPageNum;
            this.updateQsParams({ page: newPageNum });
            const qsParams = getQsParams();
            if (qsParams.searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.SPECIFICATION) {
                this.getSpecificationsPartData();
            } else {
                const filters = getFiltersForRequest(this.filters);
                this.getPartsData(filters);
            }

            withScrollToTop && window.scrollTo(0, 0);
        },
        updateQsParams(newParams = {}) {
            const urlPath = window.location.pathname;
            const queryString = Qs.stringify(
                {
                    ...getQsParams(),
                    ...newParams,
                },
                {
                    encodeValuesOnly: true,
                },
            );

            const newUrl = `${urlPath}?${queryString}${window.location.hash}`;
            window.history.pushState({ urlPath: newUrl }, document.title, newUrl);
        },
        handleUrlChange({ newURL, oldURL }) {
            // hash event fires twice,
            // because url is decoded to look readable (object properties in [])
            if (!!newURL && !!oldURL && decodeURI(newURL) === decodeURI(oldURL)) return;

            this.pagination.page = 1;

            if (this.searchResultsType.specification) {
                this.getSpecificationsPartData();
            } else {
                const filters = getFiltersForRequest(this.filters);
                this.getPartsData(filters);
            }
        },
        saveFilters(response, withFilters) {
            const isHeavyDutySearch =
                this.searchResultsType[PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_HEAVY_DUTY];
            const configsFilters = isHeavyDutySearch
                ? { sub_configs: mapQualifiersToConfigs(response.qualifiers_group) }
                : response.configs;
            if (!withFilters) {
                this.dataForSelectedFilters = null;
                this.filters = {
                    categories: response.category_tree.categories,
                    configs: configsFilters,
                    partTypes: response.category_tree.part_type_positions,
                    brands: response.brands,
                };
                return;
            }

            const { none, categories, parts, configs } = getFiltersToUpdate();

            if (none) {
                this.dataForSelectedFilters = {
                    categories: response.category_tree.categories,
                    partTypes: response.category_tree.part_type_positions,
                    brands: response.brands,
                };
                return;
            }

            const filters = {
                ...this.filters,
            };

            if (configs) {
                filters.configs = configsFilters;
            }

            if (parts) {
                filters.partTypes = response.category_tree.part_type_positions;
            }

            if (categories) {
                filters.categories = response.category_tree.categories;
            } else {
                const newCategoriesFilters = response.category_tree?.categories || [];

                filters.categories = this.filters.categories.map((category) => {
                    const newCategory = newCategoriesFilters.find(
                        (item) => item.id === category.id,
                    );

                    if (newCategory) {
                        const newSubCategories = newCategory.sub_categories;
                        return {
                            ...category,
                            sub_categories: category.sub_categories.map((subCat) => {
                                const newSubCategory = newSubCategories.find(
                                    (item) => item.id === subCat.id,
                                );

                                if (newSubCategory && !_.isEqual(newSubCategory, subCat)) {
                                    return { ...subCat, part_types: newSubCategory.part_types };
                                }

                                return subCat;
                            }),
                        };
                    }

                    return category;
                });
            }

            this.filters = filters;

            if (configs && !parts) {
                this.dataForSelectedFilters = {
                    categories: response.category_tree.categories,
                    brands: response.brands,
                };
            } else if (configs && parts) {
                if (!categories) {
                    this.dataForSelectedFilters = {
                        brands: response.brands,
                    };
                } else this.dataForSelectedFilters = null;
            }
        },
        updateEngineFiltersTable() {
            this.pagination.page = 1;
            this.getPartsData();
        },
        isPartSelected(id) {
            return !!this.selectedPartsMap[id];
        },
        togglePartSelection(event) {
            const partId = event.target.id;
            const attrName = event.target.name;

            if (this.isPartSelected(partId)) {
                this.selectedParts = this.selectedParts.filter((part) => part.id !== partId);
            } else {
                if (this.selectedParts.length === 10) {
                    event.preventDefault(); // prevent selecting checkbox before opening a popup
                    this.openNotificationModal();
                } else {
                    let newPart = {};
                    let selectedPart = null;

                    if (!attrName) {
                        if (this.viewMode === VIEW_MODES.LIST) {
                            newPart = this.partsList.find(
                                (part) => generateCustomId(part) === partId,
                            );
                        } else {
                            const partTypeList = this.partsList.map(
                                (brandGroup) => brandGroup.part_type_list,
                            );

                            const partsList = partTypeList.flat().reduce((result, item) => {
                                return [...result, ...item.parts_list];
                            }, []);

                            newPart = partsList.find((part) => generateCustomId(part) === partId);
                        }
                    } else {
                        selectedPart = this.partNumberList[attrName].find(
                            (part) => generateCustomId(part) === partId,
                        );

                        const isInterchanges =
                            CHECKBOX_ATTRIBUTE_NAME.interchange_products === attrName;

                        newPart = configurePartObject({
                            part: selectedPart,
                            isInterchanges,
                        });
                    }

                    this.selectedParts = [
                        ...this.selectedParts,
                        mapPartToSalesforceObj({ part: newPart, originalPart: selectedPart }),
                    ];
                }
            }
        },
        sendPartsData() {
            const partsData = this.selectedParts.length
                ? this.selectedParts.map(({ id, ...attributes }) => attributes)
                : [mapPartToSalesforceObj({ part: null })];

            Vue.CatalogApi.CatalogApiService.sendPartsToSalesforce(partsData)
                .then((res) => {
                    this.selectedParts = [];

                    window.Sfdc?.canvas?.client?.publish(res, {
                        name: "GPI.refresh",
                        payload: { status: "Completed" },
                    });
                })
                .catch((err) => console.error(err));
        },
        openNotificationModal() {
            this.notificationModal = !this.notificationModal;
        },
        updateNotificationModalState(modalUpdated) {
            this.notificationModal = modalUpdated;
        },
    },
});
</script>
