import { render, staticRenderFns } from "./searchResults.vue?vue&type=template&id=dd78db4a&"
import script from "./searchResults.vue?vue&type=script&lang=js&"
export * from "./searchResults.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/apps/jenkins/workspace/AEM_DEV_Publish_6.5/aem-base/content/src/main/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd78db4a')) {
      api.createRecord('dd78db4a', component.options)
    } else {
      api.reload('dd78db4a', component.options)
    }
    module.hot.accept("./searchResults.vue?vue&type=template&id=dd78db4a&", function () {
      api.rerender('dd78db4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "content/jcr_root/apps/fmmp-base/components/content/documents-autocomplite/documents-autocomplete/clientlibs/src/searchResults.vue"
export default component.exports